<template>
  <v-container class="pa-md-6 pa-2">
    <quiz-form :quiz="quiz" @addQuiz="addQuiz"></quiz-form>
  </v-container>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex"
import QuizForm from "@/components/quiz/QuizForm"
import { createQuiz } from "@/api/QuizApi"

export default {
  name: "Create",
  props: {
    type: {
      default: () => {},
      type: String,
    },
  },
  data() {
    return {
      alertmsg: null,
    }
  },
  components: {
    QuizForm,
  },
  created() {
    this.setNewQuiz(this.$route.params.type)
  },
  computed: {
    ...mapState({
      user: (state) => state.user.currentUser,
      quiz: (state) => state.quiz.currentQuiz,
    }),
  },

  methods: {
    ...mapActions(["setNewQuiz"]),
    ...mapMutations({ showAlert: "SHOW_SNACKBAR" }),
    async addQuiz() {
      try {
        const quizdata = { ...this.quiz, userId: this.user.uid }
        const quizid = await createQuiz(quizdata)
        this.$router.push({ path: `/quizzes/${quizid}` })
        this.alertmsg = "Quiz Created! Please add questions!"
        this.showAlert(this.alertmsg)
      } catch (err) {
        this.showAlert(this.err)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
#create {
  min-height: 88vh;
}
</style>
